<template>
	<el-dialog class="dialog" title="新增" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="180px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="测点名称：" prop="name">
							<el-cascader
								v-model="formData.measId"
								:options="treeData"
								:props="{
									value: 'id',
									label: 'name',
									children: 'children',
									emitPath: false
								}"
							></el-cascader>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="智寰传感器：" prop="brand">
							<el-cascader
								v-model="formData.sensorId"
								:options="treeData2"
								:props="{
									value: 'id',
									label: 'name',
									children: 'children',
									emitPath: false
								}"
								@change="handleChange"
							></el-cascader>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
var regu = /^[0-9]+\.?[0-9]*$/;
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: '',
			loading: false,
			formData: {},
			treeData: [],
			treeData2: []
		};
	},
	props: {},
	watch: {},
	methods: {
		//打开弹窗
		open() {
			this.formData = {};
			if (this.dialogObj.type == 2) {
				this.get();
			}
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			});
			this.getmeasList();
		},
		getmeasList() {
			this.$get('/backend-api/eqp/set/meas/list?meas_include=1&meas_suffix=1').then(res => {
				if (res.code != 1000) {
					this.$message.error(res.msg);
					return;
				}
				this.treeData = res.data;
			});
			this.$get('/backend-api/eqp/set/unit/meas/zhihuan/zhihuan/sensor/list').then(res => {
				if (res.code != 1000) {
					this.$message.error(res.msg);
					return;
				}
				this.treeData2 = res.data;
			});
		},
		handleChange(value) {
			this.formData.sensorName =  this.recurse(this.treeData2,value)
		},

		get() {
			// this.$get('/backend-api/comp/type/bearing/get', {
			// 	id: this.dialogObj.row.id
			// }).then(res => {
			// 	if (res.code == 1000) {
			// 		this.formData = res.data;
			// 	} else {
			// 		this.$message.error(res.msg);
			// 	}
			// });
		},
		recurse(n,d) {
			// debugger
			for(let i=0;i<n.length;i++){
				if(n[i].id == d){
					return n[i].name;
				}else{
					for(let e=0;e<n[i].children.length;e++){
						if(n[i].children[e].id == d){
							return n[i].children[e].name;
						}
					}
				}
			}
		},
		//提交表单
		submit() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.loading = true;
					this.$post('/data-api/job/zhihuan/getSensorIdBing', this.formData).then(res => {
						if (res.code == 1000) {
							this.loading = false;
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						} else {
							this.loading = false;
							this.$message.error(res.msg);
						}
					});
				} else {
					return false;
				}
			});
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep .cascader {
		display: block;
	}
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
::v-deep input[type='number'] {
	-moz-appearance: textfield;
}
</style>
